import React from 'react';
import styled from "styled-components"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Row from "../components/PortfolioLinkRow"
import SectionHeading from "../components/SectionHeading"
import LogoBlock from "../components/LogoBlock"
import Background from "../images/section-background.png"
import PortfolioBanner from "../images/landing/Banner1.jpg"
import Favicon from "../images/w-health-favicon.png"
const HomeBannerSection = styled.div`
    display:flex;
    flex-direction:row;
    width:100%;
    height:768px;
    position:relative;
    @media screen and (max-width: 991px) {
        flex-direction:column;
        height:auto;
    }
    @media screen and (max-width: 767px) {
      
    }
    @media screen and (max-width: 479px) {      
        
    }
    `;
const HomeBannerContentWrap = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:flex-start;
    width:50%;
    padding:0 2.5% 0 2.5%;;
    height:100%;
    @media screen and (max-width: 991px) {
      width:100%;
      margin-bottom:100px;
    }
    @media screen and (max-width: 479px) {
        width:100%;
        margin-bottom:40px;
      }
`;
const HomeSliderContainer = styled.div`
    width:50%;
    height:100%;  
    z-index:3;
    @media screen and (max-width: 991px) {
        width:100%;
    }
`;
const PortfolioWrap = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
    margin:60px 0 40px 0;
    padding:0 2.5vw 0 2.5vw;
    @media screen and (max-width: 479px) {
        margin:40px 0 40px 0;
    }
`;
const BackSection = styled.div`
    position:absolute;
    top:200px;
    left:0%;
    height:100%;
    z-index:-1;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-image:url(${Background});
    opacity:0.8;
`;
const Breadcrumb = styled.h1`
    font-family: Montserrat;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #2e3439;
    margin:20px 0 0px 0;
    @media screen and (max-width: 991px) {
        margin-bottom:10vh;
    }
    @media screen and (max-width: 479px) {
        margin:40px 0 5vh 0;
    }
`;
const BannerSlide = styled.img`
    width:100%;
    height:768px;
    margin-bottom:0px;
    outline:none;
    @media screen and (max-width: 991px) {
        height: 500px;
        object-position: 20% 50%;
    }
    @media screen and (max-width: 767px) {
        object-position: center;
    }
    @media screen and (max-width: 479px) {
        height:368px;
        object-position: 80% 50%;
    }
    object-fit:cover;
    object-position:60% 50%;
    transition :all 0.8s ease-in-out;
`;
const ImageWrap = styled.div`
    width:100%;
    height:100%;
    overflow:hidden;
`;
export default function Portfolio({ data }) {
    const AllPortfolio = data.allWpPortfolio.nodes;
    return (
        <Layout>
            <Seo title="Portfolio" url="https://whealthventures.com/portfolio" image={Favicon} />
            <HomeBannerSection>
                <HomeBannerContentWrap>
                    <LogoBlock></LogoBlock>
                    <Breadcrumb>Portfolio</Breadcrumb>
                    <SectionHeading top={"auto"} bottom={"auto"}>We have invested in promising ideas and backed stellar founders who are improving healthcare delivery in the US and India.</SectionHeading>
                </HomeBannerContentWrap>
                <HomeSliderContainer>
                    <ImageWrap>
                        <BannerSlide src={PortfolioBanner}></BannerSlide>
                    </ImageWrap>
                    {/* <HomeBannerSlider home={false} image={"Portfolio2.jpg"}/> */}
                </HomeSliderContainer>
                <BackSection></BackSection>
            </HomeBannerSection>

            <PortfolioWrap>
                {AllPortfolio.map((portfolio, index) => (
                    <Row key={index} data={portfolio}></Row>
                ))}
            </PortfolioWrap>
        </Layout>
    );
};
export const query = graphql`
  query {
    allWpPortfolio(sort: {order: ASC, fields: date}) {
        nodes {
          title
          pageDetails {
            companyType
            companyWebsite
            founderNames
            investorNames
            quote
            logoImage {
                mediaItemUrl
              }
          }
        }
      }
}
`
