import React,{useEffect,useState} from 'react';
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight} from '@fortawesome/free-solid-svg-icons'
import { GrShare } from "@react-icons/all-files/gr/GrShare";
import {Link} from "gatsby"
const Row = styled.a`
    text-decoration:none;
    width:100%;
    height:170px;
    margin-bottom:20px;
    display:flex;
    justify-content:flex-start;
    align-items:center;
    border-radius:30px;
    position:relative;
    transition: all .5s ease-out;
    background: linear-gradient(to left, #efefef 50%, #3c57a1 50%) right;
    background-size: 200%;
    padding:25px;
    &:hover{
        background-position: left bottom;
    }
   
    @media screen and (max-width: 991px) {
        background: #efefef;
    }
    @media screen and (max-width: 767px) {
        flex-direction:column;
        height:auto;
    }
    @media screen and (max-width: 479px) {
        height:auto;
        margin-bottom:40px;
    }
`;
const Brandlogo= styled.div`
    font-family: Roboto;
    font-size: 50px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height:50px
    letter-spacing: normal;
    text-align: left;
    transition: all .5s ease;
    text-transform:uppercase;
    color:${props => props.hoverState? '#fff':'#48494a'};
    margin-bottom:0px;
    width:25%;
    @media screen and (max-width: 991px) {
        font-size:45px;
        color:#48494a;
    }
    @media screen and (max-width: 767px) {
        width:100%;
        height:auto;
    }
`;
const CompanyLogo= styled.img`
    width:200px;
    margin:0 auto 0 auto;
`;
const TagLine = styled.h3`
    width:25%;
    height:50px;
    display:flex;
    align-items:center;
    justify-content:center;
    overlfow:hidden;
    font-family: Roboto;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    transition: all .5s ease;
    text-align:center;
    color:${props => props.hoverState? '#fff':'#48494a'};
    margin-bottom:0px;
    @media screen and (max-width: 991px) {
        font-size: 14px;
        color:#48494a;
    }
    @media screen and (max-width: 767px) {
        margin-top:20px;
        width:100%;
        font-size:16px;
        justify-content:flex-start;
        height:auto;
        text-align:left;
    }
`;
const FoundersBlock = styled.div`
    width:25%;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    @media screen and (max-width: 767px) {
        justify-content:flex-start;
        align-items:flex-start;
        width:100%;
        margin-top:20px;
    }
`;
const InvestorsBlock= styled(FoundersBlock)`

`;
const CaretIcon = styled.div`
    text-decoration:none;
    margin-bottom:0px;
    height:25px;
    width:25px;
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:20px;
    transition: all .5s ease;
    & svg path{
        transition: all .7s ease;
        stroke:${props => props.hoverState? '#fff':'#333'};
    }
    
    border-radius:50%;
    // color:#48494a;
    // box-shadow:${props=>props.hoverState?'0 7px 20px 0 rgba(0, 0, 0, 0.56)':'none'};
    @media screen and (max-width: 991px) {
        & svg path{
            transition: all .7s ease;
            stroke:#333;
        }
    }
    @media screen and (max-width: 767px) {
        height:35px;
        width:35px;
        margin-left:auto;
        margin-top:10px;
    }
`;
const Label = styled.div`
    margin-bottom:0px;
    opacity: 0.4;
    font-family: Roboto;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: normal;
    text-align: left;
    transition: all .5s ease;
    color:${props => props.hoverState? '#fff':'#48494a'};
    @media screen and (max-width: 991px) {
        font-size: 16px;
        color:#48494a;
    }
`;
const Text = styled.p`
    margin-bottom:0px;
    font-family: Roboto;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: normal;
    text-align: left;
    transition: all .5s ease;
    color:${props => props.hoverState? '#fff':'#48494a'};
    @media screen and (max-width: 991px) {
        font-size: 16px;
        color:#48494a;
    }
`;
const TextBlock=styled.div`
    color:${props => props.hoverState? '#fff':'#48494a'};
    transition: all .5s ease;
    min-height:50px;
    max-height:100px;
    text-align:center;
    display:flex;
    align-items:center;
    justify-content:flex-start;
    flex-direction:column;
    @media screen and (max-width: 991px) {
        min-height:40px;
        font-size: 16px;
        color:#48494a;
    }
    @media screen and (max-width: 767px) {
        min-height:auto;
        max-height:auto;
        align-items:flex-start;
        text-align: left;
    }
`;
const PortfolioLinkRow = (props) => {
    const [hoverState, setHoverState] = useState(false);
    const toggleHoverIn= () =>{
        setHoverState(true)
    }
    const toggleHoverOut= () =>{
        setHoverState(false)
    }
    useEffect(() => {
        setHoverState(false)
      }, []);
    return (
        <Row onMouseEnter={toggleHoverIn} onMouseLeave={toggleHoverOut} onTouchStart={toggleHoverIn} onTouchEnd={toggleHoverOut} href={props.data.pageDetails.companyWebsite} target="_blank">
            <Brandlogo hoverState={hoverState}>
                <CompanyLogo src={props.data.pageDetails.logoImage.mediaItemUrl} hoverState={hoverState}>

                </CompanyLogo>
            </Brandlogo>
            <TagLine hoverState={hoverState}>{props.data.pageDetails.companyType}</TagLine>
            <FoundersBlock>
                <Label hoverState={hoverState}>Founders</Label>
                <TextBlock hoverState={hoverState} dangerouslySetInnerHTML={{ __html: props.data.pageDetails.founderNames}} />
            </FoundersBlock>
            <InvestorsBlock>
                <Label hoverState={hoverState}>Other Investors</Label>
                <TextBlock hoverState={hoverState} dangerouslySetInnerHTML={{ __html: props.data.pageDetails.investorNames}} />
            </InvestorsBlock>
            <CaretIcon hoverState={hoverState} >
                <GrShare/>
            </CaretIcon>
        </Row>
    );
};

export default PortfolioLinkRow;